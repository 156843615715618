import Image from 'next/image';
import 'normalize.css';
import 'public/static/fonts/font-faces.css';
import 'public/static/icons/icons.css';
import 'reset-css';
import '../../styles/styles.css';
import Logo from '../Logo/Logo';
import './ServerErrorPage.css';
import { DEFAULT_LANG } from 'src/data/constants';

const ServerErrorPage = ({ reset }: {
  reset: () => void;
}) => {
  return (
    <div className="ServerErrorPage">
      <header className='ServerErrorPage__header'>
        <div className="container ServerErrorPage__headerContent">
          <Logo language={DEFAULT_LANG} />
        </div>
      </header>
      <div className="ServerErrorPage__contentWrapper container">
        <div className="ServerErrorPage__imageWrapper">
          <div className="ServerErrorPage__responsiveImage">
            <Image
              src="/static/images/500.png"
              alt="500"
              fill
              style={{ objectFit: 'contain' }}
            />
          </div>
        </div>

        <div className="ServerErrorPage__content">
          <div className="ServerErrorPage__number">500</div>
          <div className="ServerErrorPage__title">Something went wrong</div>
          <div className="ServerErrorPage__buttonWrapper">
            <button
              className='ServerErrorPage__button'
              type="button"
              onClick={reset}
            >
              Reload page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerErrorPage;
