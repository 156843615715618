'use client';

import ServerErrorPage from "src/components/ServerErrorPage/ServerErrorPage";
import { mulish } from "src/styles/fonts";

export default function GlobalError({
  reset,
}: {
  reset: () => void;
}) {
  return (
    <html>
      <head>
        <title>Something went wrong - zakaz.ua</title>
      </head>
      <body className={mulish.className}>
        <ServerErrorPage reset={reset} />
      </body>
    </html>
  );
}
